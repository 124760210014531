// Image with content.
//
// An image background with centered content on it.
//
// .image-with-content--thin - Reduces the height of the container.
// Markup: image-with-content.hbs
//
// Style guide: layouts.image-with-content

.image-with-content {
  display: block;
  position: relative;
  overflow: hidden;
  height: 320px;

  @include respond-to('m') {
    height: auto;
  }

  &__img {
    display: block;
    position: absolute;
    top: 0;
    right: -5000px;
    bottom: 0;
    left: -5000px;
    z-index: 1;

    @include respond-to('m') {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
    }

    img {
      display: block;
      max-width: 1280px;
      margin: 0 auto;
      height: 320px;

      @include respond-to(m) {
        max-width: none;
        min-height: 300px;
        height: auto;
      }

      @include respond-to(xxxl) {
        width: 100%;
      }
    }
  }

  &__content {
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 95%;
    z-index: 3;

    @include respond-to('m') {
      width: 80%;
    }

    h1,
    h2 {
      @extend %hero-h1;

      &.hero-title-with-overlay {
        font-size: rem(28px);
        float: left;
        padding: 10px 40px;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #061b27;
          opacity: 0.85;
          z-index: -1;
        }

        @include respond-to('m') {
          font-size: rem(32px);
          padding: 15px 50px;
        }
      }
    }

    h4 {
      @extend %hero-h4;
      padding-top: 10px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: color(black);
    opacity: 0.3;
    z-index: 2;
  }

  &--thin {
    .image-with-content {
      max-height: 267px;

      @include respond-to('m') {
        max-height: inherit;
      }

      &__img {
        img {
          max-width: none;
        }
      }
    }
  }
}

// Drupal Markup.
.image-with-content {
  // Spacing tweak for large-slideshow.
  .site-button--container {
    margin-top: 20px;
  }
}
