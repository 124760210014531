// Headings and Fonts
//
// General headings and font styles
//
// Markup: headings-and-fonts.hbs
//
// Style guide: components.headings-and-fonts
%feature-font {
  font-family: $open-sans;

  &--bold {
    font-weight: bold;
  }
}

%copy-font {
  font-family: $open-sans;
}

.hero-h1,
%hero-h1 {
  @extend %feature-font;
  @extend %feature-font--bold;
  font-size: rem(32px);
  line-height: 38px;
  margin: 0;
  padding-bottom: 10px;
  color: color(white);
  text-transform: uppercase;

  @include respond-to('m') {
    font-size: rem(42px);
  }
}

.hero-h4,
%hero-h4 {
  font-size: 1.125rem;
  @extend %feature-font;
  margin: 0;
  color: color(white);
  text-transform: uppercase;

  @include respond-to('m') {
    font-size: 1.4rem;
  }
}

.headding-subtitle {
  font-size: 0.96rem;
  margin: 0 0 15px;
  text-transform: uppercase;
  color: color(grey-dark);
  font-weight: 600;
}

%site-blue,
.site-blue {
  color: color(blue-aofs);
}

%site-orange,
.site-orange {
  color: color(orange-aofs);
}

.small-dark-heading {
  font-size: 1.1rem;
  font-weight: 300;
}

%small-text,
.small-text {
  @include type-layout(s, 1.1);
}

// This really shouldn't live in here, but it's not worth a new file.
.round-img {
  max-width: 300px;
  margin: 0 auto;

  img {
    border-radius: 100%;
    padding: 5px;

    @include respond-to('l') {
      box-shadow: none;
      transition: box-shadow 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 15px color(black);
      }
    }
  }
}

.white {
  color: color(white);
}
