// Body Copy for normalizing.

%body-copy,
.body-copy {
  p {
    @include type-layout(ms, 1.2);
    margin: 1rem 0;

    &:first-child,
    &:last-child {
      margin-top: 10px;
    }
  }

  h2 {
    font-size: 2.5rem;
    font-weight: normal;
  }

  li {
    &.site-button--container {
      max-width: 350px;
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  .site-button--container {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1rem;

    &--inverted {
      margin-right: auto;
      margin-left: auto;
    }
  }
}