// Icon Box.
//
// A box with an icon, title and body.

.icon-box {
  box-sizing: border-box;
  position: relative;
  text-align: center;

  &__icon {
    cursor: pointer;
    color: color(blue-aofs);

    i {
      transition: all 0.3s ease-in-out;
    }
  }

  &__title {
    padding: 15px 0;

    a {
      transition: all 0.3s ease-in-out;
      color: color(blue-aofs);
    }
  }

  &__body {
    padding-bottom: 35px;
  }

  &__link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    a {
      display: inline-block;
      position: relative;
      color: color(blue-aofs);

      @include respond-to('l') {
        &:after {
          content: '';
          max-width: 0;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.3s ease-in-out;
          height: 1px;
          background: color(blue-aofs);
        }
      }
    }
  }

  @include respond-to('l') {
    &:hover {
      .icon-box__icon {
        i {
          color: color(text);
        }
      }

      .icon-box__title {
        a {
          color: color(text);
        }
      }

      .icon-box__link {
        a {
          &:after {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// Drupal Markup.
.field-name-field-services.field-type-entityreference {
  > .field-items > .field-item {
    position: relative;
  }
}
