// Image Gallery.

.image-gallery {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .field-slideshow {
    margin: 0 auto;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .5);

    img {
      display: block;
    }
  }

  .jcarousel-list-horizontal {
    margin: 0 auto !important;

    > li {
      min-width: 75px;
      max-width: 100px;
      border: 2px solid transparent;

      &.activeSlide {
        img {
          border: 2px solid color(blue-aofs);
        }
      }
    }
  }

  .field-slideshow-controls {
    @include clearfix;
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 5px auto;

    a {
      padding: 10px 0;
    }

    .prev {
      float: left;
    }

    .next {
      float: right;
    }
  }

  .carousel-prev,
  .carousel-next {
    display: none;
  }
}
