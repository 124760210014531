// Flexslider Component
//
// Flexslider styles
//
// Markup: flexslider.hbs
//
// Style guide: layouts.flexslider

.flexslider {
  display: block;
  margin: 0;
  border: none;

  .slides {
    margin: 0;
    padding: 0;

    > li {
      list-style: none;
      display: block;
    }
  }

  .flex-control-paging {
    display: none;

    @include respond-to('m') {
      display: block;
      position: absolute;
      bottom: 10px;
      z-index: 5;

      > li {
        > a {
          border-radius: 100%;
          display: block;
          background: color(grey-dark);

          &.flex-active {
            background: color(grey-extra-light);
          }
        }
      }
    }
  }

  // Arrows.
  .flex-direction-nav {
    display: none;
  }
}
