// Convert pixels to ems.
// Context is the base font size.
@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}


@function rem($pixels, $context: 16) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

@mixin grid($columns, $item-selector: '> *', $padding: 15px, $include-clears: true) {
  margin: {
    left: (-1 * ($padding/2));
    right: (-1 * ($padding/2));
  }
  @include clearfix;
  #{$item_selector} {
    padding: {
      left: $padding / 2;
      right: $padding / 2;
    }
    float: left;
    box-sizing: border-box;
    width: 100%/ $columns;
    @if $include-clears {
      &:nth-of-type(#{$columns}n+1) {
        clear: both;
      }
    }
  }
}

// More efficiently update a grid system for responsive.
@mixin grid-update($columns, $item-selector: '> *', $padding: 15px, $include-clears: true) {
  margin: {
    left: (-1 * ($padding/2));
    right: (-1 * ($padding/2));
  }

  #{$item_selector} {
    padding: {
      left: $padding / 2;
      right: $padding / 2;
    }
    width: 100%/$columns;
    @if $include-clears {
      &:nth-of-type(n) {
        clear: none;
      }
      &:nth-of-type(#{$columns}n+1) {
        clear: both;
      }
    }
  }
}

@mixin triangle($width, $color, $skinny-factor: 0.717) {
  width: 0;
  height: 0;
  border-color: $color transparent transparent transparent;
  border-width: $width ($width * $skinny-factor) 0 ($width * $skinny-factor);
  border-style: solid;
}

