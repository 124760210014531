// Simple Grid Component.

// Drupal Markup.
.pane-paragraphs-item-field-services {
  .field-type-entityreference {
    > .field-items {
      > .field-item {
        width: 100%;
        display: block;
        padding: 15px 0;
        float: none;
      }

      @include respond-to('m') {
        @include grid(2, '> .field-item', 30px);
      }

      @include respond-to('l') {
        @include grid-update(4, '> .field-item', 40px);
      }
    }
  }
}

.view-team-members {
  .views-row {
    width: 100%;
    display: block;
    padding: 15px 0;
    float: none;
    text-align: center;
  }

  @include respond-to('m') {
    @include grid(3, '.views-row', 40px);
  }
}
