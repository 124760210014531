// News section.

.view-id-news {
  margin-bottom: 2rem;

  .view-content {
    @include clearfix;
  }

  .views-row {
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid color(grey-light);

    .field-name-title-field {
      h2 {
        margin: 0;
      }

      a {
        color: color(orange-aofs);
      }
    }

    .pane-node-created {
      margin: .3rem 0 1rem;
    }

    .pane-node-field-teaser-text {
      margin: 1rem 0;
    }

    .pane-view-node-link {
      a {
        color: color(blue-aofs);
        transition: color .3s ease-in-out;

        &:hover {
          color: color(orange-aofs);
        }
      }
    }

    @include respond-to(xs) {
      border: none;
    }
  }

  @include respond-to(xs) {
    @include grid(2, '.views-row', 30px);
  }

  @include respond-to(m) {
    @include grid-update(3, '.views-row', 40px);
  }
}


//> .field-item {
//  width: 100%;
//  display: block;
//  padding: 15px 0;
//  float: none;
//}
//
//@include respond-to('m') {
//  @include grid(2, '> .field-item', 30px);
//}
//
//@include respond-to('l') {
//  @include grid-update(4, '> .field-item', 40px);
//}
//}