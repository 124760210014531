// Section Break.

.section-break {
  @extend %spacer--padding;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  background: color(blue-aofs);

  &:after {
    @include triangle(30px, color(blue-aofs), 0.9);
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }

  &__title {
    color: color(white);

    h2 {
      font-size: 2rem;
      font-weight: normal;
      margin-top: 0;
    }
  }

  &__sub-title {
    color: color(white);

    h4 {
      font-weight: normal;
      margin-bottom: 0;
    }
  }
}