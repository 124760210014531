// Full Background.

.full-bg {
  &--blue {
    color: color(white);
    background: color(blue-aofs-dark);

    a {
      color: color(white) !important;
    }
  }
}
