// Main Header.
//
// .main-header--sticky - Make the header sticky
//
// Markup: main-header.twig
//
// Style guide: components.main-header

.main-header {
  display: block;
  width: 100%;
  background: color(main-header-bg);

  @include respond-to('m') {
    border-bottom: 1px solid color(grey-light);
  }
}

// Drupal Markup.
.main-header {
  .layout-2col {
    &__col {
      &--first {
        padding: 5px 1rem 0;
      }

     @include respond-to('m') {
       height: 100%;

       .pane-main-menu {
         height: 100%;
       }

       &--first {
         width: 40%;
       }

       &--last {
         width: 60%;
       }
     }
    }

    @include respond-to('m') {
      height: 110px;
    }
  }

  #logo img {
    display: block;
    height: 100px;
    margin: 0 auto;
    transform: translate3d(0, 0, 1px);
  }
}
