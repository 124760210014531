// Site Layout
//
// Site layout tweaks and styles.
//

.site-layout {
  overflow: hidden;

  &__content {
    > .pane__title {
      display: none;
    }
  }

  &__footer {
    .pane-main-menu {
      .pane__title {
        display: none;
      }

      .menu-block-wrapper {
        .menu {
          @include clearfix;
          margin: 0 auto;
          padding: 0;
          text-align: center;

          li {
            list-style: none;

            a {
              display: block;
              padding: 5px;
              margin: 10px 0;
            }
          }

          @include respond-to(m) {
            display: table;

            li {
              float: left;

              a {
                padding: 0;
                margin: 10px 15px;
              }
            }
          }

          @include respond-to(l) {
            display: block;
            text-align: left;
          }
        }
      }

      @include respond-to(l) {
        float: left;
      }
    }

    .pane-custom.pane-1 {
      display: block;
      width: 100%;
      text-align: center;

      @include respond-to(l) {
        width: auto;
        float: right;
        text-align: right;

        p {
          margin: 10px 15px;
        }
      }
    }
  }
}

// Webforms - sorry :).

#user-login,
.node-webform.node-teaser {
  clear: both;

  input[type="submit"] {
    display: block;
    width: 100%;
    color: color(white);

    @include respond-to(xs) {
      float: right;
      width: auto;
    }
  }

  @include respond-to(xs) {
    max-width: 900px;
    margin: 0 auto;
  }
}

#user-login {
  padding: 30px 0;

  .form-actions {
    @include clearfix;
  }
}
