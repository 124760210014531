// Spacer for vertical rythmn control.

$space: 30px;

%spacer,
.spacer {
  margin: $space 0;

  &--top-only {
    margin-bottom: 0;
  }

  &--bottom-only {
    margin-top: 0;
  }

  &--double {
    margin: ($space * 2) 0;
  }

  &--half {
    margin: ($space / 2) 0;
  }

  &--padding {
    padding: $space 0;

    &--half {
      padding: ($space / 2) 0;
    }

    &--top {
      padding-bottom: 0;
    }

    &--bottom {
      padding-top: 0;
    }
  }
}

// Drupal Markup.
.field-name-field-widgets {
  > .field-items {
    // Vertical spacing for paragraphs items.
    > .field-item {
      .paragraphs-item-services-teaser-grid,
      .paragraphs-item-free-text,
      .paragraphs-item-team-members {
        @extend %spacer--padding;
      }

      &:first-child {
        > .paragraphs-item {
          padding-top: 0;
        }
      }

      .paragraphs-item-form {
        @extend %spacer--padding;
        @extend %spacer--padding--bottom;
      }
    }
  }
}
