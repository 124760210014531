// Layout 2 col.

.layout-2col {
  @include clearfix;

  &__col {
    position: relative;

    @include respond-to(m) {
      float: left;
      width: 50%;

      &--first {
        padding-right: 7.5px;
      }

      &--last {
        padding-left: 7.5px;
      }
    }
  }
}
