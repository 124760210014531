// Social Links

.social-links {
  &--visible {
    visibility: visible !important;
    opacity: 1 !important;
    transform: translateX(0) !important;
  }
}

.pane-social-media-links-social-media-links {
  position: fixed;
  top: auto;
  bottom: 10px;
  transform: translateX(-100%) translateY(-50%);
  z-index: 100;
  transition: all .3s ease-in-out;
  visibility: hidden;
  opacity: 0;

  ul {
    display: block;
    margin: 0;
    padding: 0;

    li {
      display: block;
      height: 32px;
      margin-bottom: 5px;

      a {
        display: block;

        img {
          display: block;
        }
      }
    }
  }

  .pane__title {
    display: none;
  }

  ul {
    display: block;
  }

  @include respond-to(xs) {
    top: 50%;
    left: 0;
    bottom: auto;
  }
}
