// Horizontal Main Nav.

.horizontal-main-nav {
  // Mobile menu.
  > .pane__title {
    display: block;
    position: relative;
    padding: 10px 1rem;
    margin-top: 10px;
    text-align: left;
    font-size: 1.3rem;
    color: color(white);
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: -500px;
      right: -500px;
      top: 0;
      bottom: 0;
      background: color(blue-aofs);
      z-index: -1;
    }

    &:after {
      content: '\f0c9';
      font: $font-awesome;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 1.3rem;
      z-index: 1;
    }

    @include respond-to('m') {
      display: none;
    }
  }

  .menu-block-wrapper {
    display: none;

    @include respond-to('m') {
      display: block !important;
    }
  }

  &--active {
    > .pane__title {
      &:after {
        content: '\f00d';
      }
    }
  }

  .menu {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      display: block;
      list-style: none;

      a {
        display: block;
        position: relative;
        padding: 10px 0;
        color: color(blue-aofs);
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          left: -100px;
          right: -100px;
          bottom: 0;
          height: 1px;
          background: color(grey-light);
          z-index: -1;
        }

        &.active {
          color: color(orange-aofs);
        }
      }
    }

    @include respond-to('m') {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      text-align: left;

      li {
        float: left;

        a {
          padding: 15px;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}