// Site Button
//
// A button themed for the site.
//
// Markup: site-button.hbs
//
// Style guide: components.site-button

%site-button,
.site-button {
  display: inline-block;
  padding: 10px 20px;
  transition: background 0.3s linear;
  font-size: 1em;
  color: color(site-button-txt);
  background: color(site-button-bg);
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;

  &:hover,
  &--active {
    background: color(site-button-active-bg);
  }

  &--inverted {
    color: color(site-button-txt);
    background: color(site-button-active-bg);

    &:hover,
    .site-button--active {
      background: color(site-button-bg);
    }
  }
}

// For cases when class can't be added directly to element.
.site-button--container {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;

  a,
  input {
    @extend %site-button;
  }

  &--inverted {
    max-width: 250px;
    margin: 0 auto;
    text-align: center;

    a,
    input {
      @extend %site-button;
      color: color(site-button-txt);
      background: color(site-button-active-bg);

      &:hover {
        background: color(site-button-bg);
      }
    }
  }
}

input[type="submit"] {
  @extend %site-button;
  appearance: none;
  border: none;
}
