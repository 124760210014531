// Site Width
//
// Place restrictions around the size and positioning of the content region.
//
// Markup: site-width.twig
//
// Style guide: layouts.site-width

%site-width,
.site-width {
  @include clearfix;
  display: block;
  position: relative;
  max-width: rem(1530px);
  margin: 0 auto;
  padding: 0 2.1rem;
}
